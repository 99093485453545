import axios from "axios";
import router from "@/router";
import storage from "@/services/web-storage";
import store from "@/store";

const API_URL = process.env.VUE_APP_API_BASE_URL + "auth/";

class AuthService {
    #refreshingToken=false;
    #uid;
    constructor() {
        this.#uid = this.generateDeviceId();
    }
    generateDeviceId() {
        let navigator_info = window.navigator;
        let screen_info = window.screen;
        let uid = navigator_info.userAgent.replace(/\D+/g, "");
        uid += screen_info.height || "";
        uid += screen_info.width || "";
        uid += screen_info.pixelDepth || "";
        return uid;
    }
    async checkAccessToken() {
        if (this.#refreshingToken) {
            return new Promise(resolve => {
                setTimeout(async () => {
                    resolve(await this.checkAccessToken())
                }, 1);
            });
        }
        if (await this.isAccessValid()) {
            return storage.getAccessToken();
        }
        else {
            return null;
        }
    }
    async isAccessValid() {
        return storage.getAccessToken()
            && storage.getAccessExpiry()
            && (Date.now() < storage.getAccessExpiry())
            || await this.token_refresh();
    }
    async token_refresh() {
        if (this.isRefreshValid()) {
            this.#refreshingToken = true;
            const res = await this.authentication({
                url: API_URL + "refresh/",
                data: { refresh: storage.getRefreshToken() },
                config: {
                    headers: {
                        Authorization: `Bearer ${storage.getRefreshToken()}`
                    },
                    params: {
                        deviceId: this.#uid
                    }
                }
            });
            setTimeout(() => this.#refreshingToken = false);
            return res;
        }
        else return false;
    }
    isRefreshValid() {
        return storage.getRefreshToken()
            && storage.getRefreshExpiry()
            && (Date.now() < storage.getRefreshExpiry());
    }
    async authentication({url, data, config}) {
        try {
            const response = await axios.post(url, data, config);
            if (response.status === 200) {
                await this.onLoginSuccess(response.data.auth || response.data);
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }
    async onLoginSuccess({access, accessExpiresIn=7200, refresh, refreshExpiresIn=1209600}) {
        if (access) storage.setAccessToken(access);
        if (accessExpiresIn) {
            const t = Date.now() + accessExpiresIn;
            storage.setAccessExpiry(t);
        }
        if (refresh) storage.setRefreshToken(refresh);
        if (refreshExpiresIn) {
            const t = Date.now() + refreshExpiresIn * 1000;
            storage.setRefreshExpiry(t);
        }
    }
    async login_code(email, recaptchaToken) {
        try {
            await axios.post(API_URL + "code/", { email, recaptchaToken, deviceId: this.#uid });
            return true;
        }
        catch (e) { return false; }
    }
    async login_email(email, code) {
        return await this.authentication({
            url: API_URL + "login/email/",
            data: { email, code, deviceId: this.#uid }
        });
    }
    async login(login, password) {
        return await this.authentication({
            url: API_URL + "login/login/",
            data: { login, password, deviceId: this.#uid }
        });
    }
    async login_uuid(uuid) {
        return await this.authentication({
            url: API_URL + "login/uuid/",
            data: { uuid }
        });
    }
    async logout() {
        await storage.clear(); // web-storage
        await store.dispatch("reset", null, { root: true }); //vuex
        try {
            await router.push("/");
        } catch (e) {
            await router.go(0)
        }
    }
}
export default new AuthService();