// State initial object
import AuthService from '../../services/auth.service';
import ApiSecure from "@/services/api/api-secure";
import authService from "../../services/auth.service";

const initialState = () => ({
	email: null,
	role: null,
	viewPermissions: [],
	credentials: {
		email: "",
		captcha: "",
		code: "",
		uuid: "",
		login: "",
		password: "",
	}
});


/* Module .store.js */


// VUEX STATE
const state = initialState();


// VUEX GETTERS
const getters = {
	getRole: (state) => state.role,
	getPermissions: (state) => state.viewPermissions,
	getEmail: (state) => state.email,
	getCredentialsEmail: (state) => state.credentials.email,
	getCredentialsCaptcha: (state) => state.credentials.captcha,
	getCredentialsPassword: (state) => state.credentials.password,
	getCredentialsCode: (state) => state.credentials.code,
	isLoggedIn: (state) => !!state.role
};


// VUEX ACTIONS
const actions = {
	reset({commit}) {
		commit('RESET');
	},
	async init({ commit }) {
		commit('SET', await ApiSecure.get('user/info/'));
	},
	async code({ state }) {
		return await AuthService.login_code(state.credentials.email,state.credentials.captcha);
	},
	async login({ state }) {
		return await AuthService.login(state.credentials.login, state.credentials.password)
	},
	async uuid({ state }) {
		return await AuthService.login_uuid(state.credentials.uuid)
	}
};


// VUEX MUTATIONS
const mutations = {
	RESET(state) {
		const newState = initialState();
		Object.keys(newState).forEach(key => {
			state[key] = newState[key]
		});
	},
	SET(state, {email, role, viewPermissions}) {
		state.email = email || null;
		state.role = role || null;
		state.viewPermissions = viewPermissions || [];
	},
	EMAIL(state, payload) {
		state.credentials.email = payload;
	},
	PASSWORD(state, payload) {
		state.credentials.password = payload;
	},
	CAPTCHA(state, payload) {
		state.credentials.captcha = payload;
	},
	CODE(state, payload) {
		state.credentials.code = payload;
	},
	LOGIN(state, payload) {
		state.credentials.login = payload;
	},
	UUID(state, payload) {
		state.credentials.uuid = payload;
	},
};


export default {
	state,
	getters,
	actions,
	mutations
};